export default {
  name: 'accountIndex',
  data: function data() {
    return {
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      userList: [{
        id: 1,
        name: '基本信息',
        icon: require('../../assets/img/index/user_01-info.png'),
        nowIcon: require('../../assets/img/index/user_01-info-selected.png'),
        path: '/account/basicInfo'
      }, {
        id: 2,
        name: '安全设置',
        icon: require('../../assets/img/index/user_02-security.png'),
        nowIcon: require('../../assets/img/index/user_02-security-selected.png'),
        path: '/account/security'
      },
      // {
      //   id:3,
      //   name:'通知消息',
      //   icon:require('../../assets/img/index/user_03-notice.png'),
      //   nowIcon:require('../../assets/img/index/user_03-notice-selected.png'),
      //   path:'/account/notice'
      // },
      {
        id: 4,
        name: '操作记录',
        icon: require('../../assets/img/index/user_04-record.png'),
        nowIcon: require('../../assets/img/index/user_04-record-selected.png'),
        path: '/account/operationLog'
      }, {
        id: 5,
        name: '退出登录',
        icon: require('../../assets/img/index/user_05-exit.png'),
        nowIcon: require('../../assets/img/index/user_05-exit-selected.png'),
        path: 'logout'
      }],
      breadList: [],
      centerDialogVisible: false
    };
  },
  components: {},
  created: function created() {},
  mounted: function mounted() {
    this.getBreadCrumb();
  },
  methods: {
    getBreadCrumb: function getBreadCrumb() {
      var matched = this.$route.matched;
      this.breadList = matched;
    },
    goUser: function goUser(pathVal) {
      if (pathVal === 'logout') {
        this.centerDialogVisible = true;
      } else {
        this.$router.push({
          path: pathVal
        });
      }
    },
    //弹框确定函数
    confirmFun: function confirmFun() {
      var _this = this;
      //Logout
      this.service.axiosUtils({
        requestName: 'Logout',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.centerDialogVisible = false;
            _this.$toast.success(res.message);
            localStorage.clear();
            _this.$router.push({
              path: '/login'
            });
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    }
  },
  watch: {
    $route: function $route() {
      this.getBreadCrumb();
    }
  }
};